import React, { useState } from "react";
import {
    Button,
    Form,
    Input,
    Modal,
    Spin,
} from "antd";
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

// API
import {
    addUser,
    checkIsEmailExist,
} from "../../Constants/Api/functions/function";

// Assets
import signinBg from '../../Assets/Images/signinBg.png'
import signin from '../../Assets/Images/signin.png'

import './style.scss'

const Register = () => {
    const [isLoading, setIsLoading] = useState(false)


    const checkEmail = async (email) => {
        const isEmailExist = await checkIsEmailExist(email);
        return isEmailExist;
    }

    const onFinish = async (values) => {
        const isEmailExist = await checkEmail(values.email);

        if (isEmailExist) {
            emailAlreadyExistsModal()
        } else {
            const user = {
                name: values.name,
                email: values.email,
                address: values.address,
                password: values.password,
                role: 'student',
                contactNumber: values.contactNumber,
            }

            await handleUserRegister(user)
        }
    };

    const handleUserRegister = async (user) => {
        try {
            setIsLoading(true)
            const response = await addUser(user)

            if (response && response.status === 201) {
                successModal()

                setTimeout(() => {
                    window.location.href = '/login'
                }, 3000);
            } else {
                console.log("error response", response)
                const errorMessage = getErrorMessage(response);
                errorModal(errorMessage);
            }

        } catch (error) {
            console.log("error", error)
            const errorMessage = getErrorMessage(error);
            errorModal(errorMessage);
        } finally {
            setIsLoading(false);
        }
    }

    const getErrorMessage = (error) => {
        if (!error) return 'An unknown error occurred';

        if (error.response?.data?.message) {
            return error.response.data.message;
        }

        return error.message || 'An error occurred during registration';
    };

    const errorModal = (errorMessage) => {
        Modal.error({
            title: 'Registration Failed',
            content: `Please check your information and try again. ${errorMessage}`,
            className: 'register-modal',
            okText: 'Close'
        });
    };

    const successModal = () => {
        Modal.success({
            title: 'Successfully Registered',
            content: 'You have successfully registered. You will be redirected to the login page in a few seconds.',
            className: 'register-modal',
            onOk() {
                window.location.href = '/login'
            }
        });
    }

    const emailAlreadyExistsModal = () => {
        Modal.error({
            title: 'Error',
            content: 'The email address is already in use by another account, please try again with another email address.',
            className: 'register-modal'
        });
    }

    return (
        <div className='signup-page'
            style={{
                backgroundImage: `url(${signinBg})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            }}
        >
            <section className="form">
                <div className="signin-top"
                    style={{
                        backgroundImage: `url(${signin})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}
                >
                    <h1 className="title">
                        Please fill the form to create your account
                    </h1>
                </div>
                <Spin spinning={isLoading}>
                    <Form
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            className='form-item'
                            name="name"
                            label="Name with Initials"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your name with initials!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || value.length <= 50) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Name should be less than 50 characters!'));
                                    },
                                }),
                                {
                                    pattern: /^[A-Za-z\s.]*$/,
                                    message: 'Please use only English characters!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter your name with initials" />
                        </Form.Item>

                        <Form.Item
                            className='form-item'
                            label="Email Address"
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Email address!',
                                },
                                {
                                    pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                    message: 'The input is not valid E-mail!',
                                }
                            ]}
                        >
                            <Input placeholder="sampleuser@gmail.com" />
                        </Form.Item>

                        <Form.Item
                            className='form-item'
                            name="password"
                            label="Password for your Digital Academy account"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your password',
                                },
                                { min: 6 },
                            ]}
                        >
                            <Input.Password
                                placeholder="Input password"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>


                        <Form.Item
                            className='form-item'
                            label="Confirm Password"
                            name="conPassword"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The new password that you entered do not match!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                placeholder="Input password"
                                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>

                        <Form.Item
                            className='form-item'
                            label="Contact Number"
                            name="contactNumber"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your contact number!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || /^0[0-9]{9}$/.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The input is not a valid phone number (e.g., 0XX XXXX XXX)!'));
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="0XX XXXX XXX" />
                        </Form.Item>

                        <Form.Item
                            className='form-item'
                            label="Address"
                            name="address"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your address!',
                                },
                            ]}
                        >
                            <Input placeholder="Enter your address" />
                        </Form.Item>

                        <Form.Item className='form-bottom'>
                            <Button className='form-button' type="primary" htmlType="submit"
                            >
                                Register
                            </Button>

                            <div className="sign-up">
                                <p>Already have an account <a href="/login">Sign in</a></p>
                            </div>
                        </Form.Item>
                    </Form>
                </Spin>
            </section>
        </div>
    )
}

export default Register